import React from 'react'
import { makeStyles } from '@material-ui/styles'
import { Box, Container, Grid, Hidden, Typography } from '@material-ui/core'

import Icon1 from '../../svg/advtg-icon-1.svg'
import Icon2 from '../../svg/advtg-icon-2.svg'
import Icon3 from '../../svg/advtg-icon-3.svg'
import Icon4 from '../../svg/advtg-icon-4.svg'
import { StaticImage } from 'gatsby-plugin-image'

const useStyles = makeStyles((theme) => ({

  wrapper: {
    background: [theme.palette.gradientDark.main],
    color: '#fff',
    padding: '70px 0 100px 0',
    overflow: 'hidden',
    position: 'relative',
    zIndex: 5,
  },

  titleBox: {
    marginBottom: '90px',
  },

  listItem: {
    display: 'flex',
    alignItems: 'center',

    '& svg': {
      flex: '0 0 62px',
      marginRight: '30px',
    },

    '&:not(:last-child)': {
      marginBottom: '50px',
    },

    '& .MuiTypography-body1': {
      fontSize: '1.1rem',
    },
  },

  [theme.breakpoints.up('md')]: {

    circle: {
      position: 'absolute',
      width: 'calc(100vw / 1.5)',
      height: 'calc(100vw / 1.5)',
      borderRadius: '50%',
      background: [theme.palette.gradientBlue.main],
      bottom: 'calc((-100vw / 1.5) * 0.5)',
      right: 'calc((-100vw / 1.5) * 0.35)',
    },

    illustration: {
      position: 'absolute',
      top: 'calc(-100vw / 9.8)',
      left: 'calc(-100vw / 25)',
      width: 'calc(100vw / 2.18)',
      height: 'calc(100vw / 2.51)',
    },

    listItem: {
      '& .MuiTypography-body1': {
        fontSize: '1.5rem',
      },
    },
  }
}))

const AboutSection = () => {

  const classes = useStyles()

  const advantages = [
    {
      icon: <Icon1 />,
      desc: 'Сделаем сайт от простого лендинга до сложного интернет-магазина и блога',
      id: 'about-item-1'
    },
    {
      icon: <Icon2 />,
      desc: 'Сделаем все страницы релевантными и SEO оптимизированными для лучшего ранжировании в поиске',
      id: 'about-item-2'
    },
    {
      icon: <Icon3 />,
      desc: 'Гибкая система цен. Предложим вам несколько тарифов, в зависимости от вашего бюджета и необходимого функционала',
      id: 'about-item-3'
    },
    {
      icon: <Icon4 />,
      desc: 'Все работы выполняем точно в срок',
      id: 'about-item-4'
    },
  ]

  return (
    <Box
      component="section"
      className={classes.wrapper}
    >
      <Hidden mdDown>
        <div className={classes.circle}>
          <StaticImage 
            src="../../svg/illustration2.svg"
            quality={100}
            alt="Ascent веб студия"
            className={classes.illustration}
          />
        </div>
      </Hidden>
      <Container maxWidth="xl">
        <Box className={classes.content}>
          <Grid container>
            <Grid item md={7} lg={6}>
              <Box className={classes.gridItem}>
                <Box className={classes.titleBox}>
                  <Typography variant="h2" className={`${classes.title} title`}>создаем сайты любой сложности</Typography>
                </Box>
                <Box className={classes.listBox}>
                  {
                    advantages.map((item, idx) => (
                      <Box className={classes.listItem} key={`${item.id}-${idx}`}>
                        {item.icon}
                        <Typography variant="body1">{item.desc}</Typography>
                      </Box>
                    ))
                  }
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Container>
    </Box>
  )
}

export default AboutSection
